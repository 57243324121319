
import { defineComponent, PropType } from "vue";
import { EsArtworkDto } from "@kunsten/core";
import ArtworkCard from "../../../shared/components/ArtworkCard.vue";
import TitleYearLabel from "../../../shared/components/TitleYearLabel.vue";

export default defineComponent({
    name: "SearchSimpleResult",
    components: { ArtworkCard, TitleYearLabel },
    props: {
        entity: Object as PropType<EsArtworkDto>,
        big: {
            type: Boolean,
            default: false,
        },
        fullHeight: {
            type: Boolean,
            default: false,
        },
    },
});
