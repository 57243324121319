import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchResultsTwoColumn = _resolveComponent("SearchResultsTwoColumn")!
  const _component_SearchResultsFourColumn = _resolveComponent("SearchResultsFourColumn")!
  const _component_SearchResultsDetailed = _resolveComponent("SearchResultsDetailed")!

  return (_openBlock(), _createElementBlock("section", {
    class: "search-results",
    "aria-label": _ctx.$t('search.list')
  }, [
    (_ctx.actualDisplayMode === 'two-column')
      ? (_openBlock(), _createBlock(_component_SearchResultsTwoColumn, {
          key: 0,
          class: "pt-6 pb-5",
          results: _ctx.results
        }, null, 8, ["results"]))
      : (_ctx.actualDisplayMode === 'four-column')
        ? (_openBlock(), _createBlock(_component_SearchResultsFourColumn, {
            key: 1,
            class: "pt-6 pb-5",
            results: _ctx.results
          }, null, 8, ["results"]))
        : (_ctx.actualDisplayMode === 'detailed')
          ? (_openBlock(), _createBlock(_component_SearchResultsDetailed, {
              key: 2,
              class: "py-6",
              results: _ctx.results
            }, null, 8, ["results"]))
          : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}