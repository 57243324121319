import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5617982a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-results-four-column columns is-multiline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchSimpleResult = _resolveComponent("SearchSimpleResult")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "column",
        key: column
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedResults[column - 1], (result, index) => {
          return (_openBlock(), _createBlock(_component_SearchSimpleResult, {
            key: result['@id'],
            entity: result,
            fullHeight: true,
            class: _normalizeClass(["scroll-transition", { active: index === 0 && !_ctx.isMobile }])
          }, null, 8, ["entity", "class"]))
        }), 128))
      ]))
    }), 128))
  ]))
}