
import { defineComponent, PropType } from "vue";
import { EsArtworkDto } from "@kunsten/core";
import LabelledText from "../../../shared/components/LabelledText.vue";
import EsDate from "../../../shared/components/EsDate.vue";

export default defineComponent({
    name: "SearchDetailedResult",
    components: { LabelledText, EsDate },
    props: {
        entity: {
            type: Object as PropType<EsArtworkDto>,
            required: true,
        },
    },
    setup() {
        // In some cases elastic search return birth/death date
        // as period e.g. 1910-09-16 - 1910-09-16
        const sliceDate = (value: string) => {
            // The only thing we can do then is to slice the string value
            // 1910-09-16 - 1910-09-16 => 1910-09-16
            if (value && value.length > 10) return value.slice(0, 10);
            return value;
        };

        return { sliceDate };
    },
});
