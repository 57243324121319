
import { computed, defineComponent, PropType } from "vue";
import SearchResultsTwoColumn from "./SearchResultsTwoColumn.vue";
import SearchResultsFourColumn from "./SearchResultsFourColumn.vue";
import SearchResultsDetailed from "./SearchResultsDetailed.vue";
import { useViewport, BREAKPOINTS } from "@kunsten/core";
import { DisplayMode } from "./search-types";

export default defineComponent({
    name: "SearchResults",
    components: { SearchResultsTwoColumn, SearchResultsFourColumn, SearchResultsDetailed },
    props: {
        results: {
            type: Array,
            default: () => [],
        },
        displayMode: {
            type: String as PropType<DisplayMode>,
        },
    },
    setup(props) {
        const { matches: isMobile } = useViewport(BREAKPOINTS.mobile);
        const actualDisplayMode = computed(() => {
            // on mobile we're not giving users the possibility to choose a display mode, hence we fallback to four-columns
            return isMobile.value ? "four-column" : props.displayMode;
        });
        return { actualDisplayMode };
    },
});
