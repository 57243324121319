import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "search-results-two-column columns is-multiline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchSimpleResult = _resolveComponent("SearchSimpleResult")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result, index) => {
      return (_openBlock(), _createBlock(_component_SearchSimpleResult, {
        key: result['@id'],
        entity: result,
        big: true,
        class: _normalizeClass(["column is-half-tablet mb-4 scroll-transition", { active: index < 2 }])
      }, null, 8, ["entity", "class"]))
    }), 128))
  ]))
}