import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleYearLabel = _resolveComponent("TitleYearLabel")!
  const _component_ArtworkCard = _resolveComponent("ArtworkCard")!

  return (_openBlock(), _createBlock(_component_ArtworkCard, {
    key: _ctx.entity['id'],
    imgSrc: _ctx.entity.reproductions.length ? _ctx.entity.reproductions[0] : null,
    artworkId: _ctx.entity['id'],
    big: _ctx.big,
    fullHeight: _ctx.fullHeight
  }, {
    title: _withCtx(() => [
      _createVNode(_component_TitleYearLabel, { artwork: _ctx.entity }, null, 8, ["artwork"])
    ]),
    creators: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.entity.creatorsNames.join(", ")), 1)
    ]),
    _: 1
  }, 8, ["imgSrc", "artworkId", "big", "fullHeight"]))
}