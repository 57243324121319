import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27f94c5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-load-more" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "fas fa-chevron-down",
  "data-testid-arrow": ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading || _ctx.canLoadMore)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn gossamer p-5",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('load'))),
          disabled: !_ctx.canLoadMore,
          "data-testid-button": ""
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("search.show-more")), 1),
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("i", _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_Spinner, {
                key: 1,
                size: "sm"
              }))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}