
import { defineComponent } from "vue";
import Spinner from "../../../shared/components/Spinner.vue";

export default defineComponent({
    name: "SearchLoadMore",
    components: { Spinner },
    emits: ["load"],
    props: {
        loading: Boolean,
        canLoadMore: Boolean,
    },
});
