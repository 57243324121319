
import { defineComponent } from "vue";
import SearchSimpleResult from "./SearchSimpleResult.vue";
import { useScrollTransition } from "@kunsten/core";

export default defineComponent({
    name: "SearchResultsTwoColumn",
    components: { SearchSimpleResult },
    props: {
        results: Array,
    },
    setup() {
        useScrollTransition();
    },
});
