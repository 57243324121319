
import { defineComponent, PropType } from "vue";
import { DisplayMode } from "./search-types";

export default defineComponent({
    name: "SearchDisplayMode",
    emits: ["changed"],
    props: {
        displayMode: {
            type: String as PropType<DisplayMode>,
            default: "four-column",
        },
    },
    methods: {
        setMode(mode: DisplayMode) {
            this.$emit("changed", mode);
        },
    },
});
