import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48c1e0b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-detailed-result" }
const _hoisted_2 = { class: "search-detailed-result-left" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "far fa-image"
}
const _hoisted_5 = { class: "search-detailed-result-right" }
const _hoisted_6 = { class: "search-detailed-result-right-title" }
const _hoisted_7 = { class: "search-detailed-result-right-columns" }
const _hoisted_8 = { class: "search-detailed-result-right-column" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "search-detailed-result-right-column" }
const _hoisted_14 = { class: "search-detailed-result-right-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LabelledText = _resolveComponent("LabelledText")!
  const _component_EsDate = _resolveComponent("EsDate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("figure", null, [
        (_ctx.entity.reproductions.length)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.entity.reproductions[0] + '/download?width=650&height=550',
              alt: ""
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        (!_ctx.entity.reproductions.length)
          ? (_openBlock(), _createElementBlock("i", _hoisted_4))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, {
          to: { name: 'ArtworkPage', params: { id: _ctx.entity.id } }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.entity.title || "-"), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_LabelledText, { tiny: true }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artist.full-name")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.entity.creatorsNames[0] || "-"), 1),
              (_ctx.entity.creatorsNames.length > 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, ", ..."))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, { tiny: true }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artist.year-of-birth")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.sliceDate(_ctx.entity.creatorsBirthDates[0])), 1),
              (_ctx.entity.creatorsBirthDates.length > 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, ", ..."))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, { tiny: true }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artist.year-of-death")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.sliceDate(_ctx.entity.creatorsDeathDates[0])), 1),
              (_ctx.entity.creatorsDeathDates.length > 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, ", ..."))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, { tiny: true }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artist.nationality")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.entity.creatorsNationalities[0] || "-"), 1),
              (_ctx.entity.creatorsDeathDates.length > 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, ", ..."))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_LabelledText, { tiny: true }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.materials")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.entity.materials.join(", ") || "-"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, { tiny: true }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.dimensions")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.entity.dimensions || "-"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, { tiny: true }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.artType")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.entity.artType || "-"), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_LabelledText, { tiny: true }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.inventory-number")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.entity.inventoryNumber || "-"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, { tiny: true }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.acquisition-reason")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.entity.acquisitionReason || "-"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, { tiny: true }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.production-date")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createVNode(_component_EsDate, {
                format: "year",
                startDate: _ctx.entity.productionStartDate,
                endDate: _ctx.entity.productionEndDate
              }, null, 8, ["startDate", "endDate"])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}