
import { defineComponent, PropType, ref, computed } from "vue";
import SearchSimpleResult from "./SearchSimpleResult.vue";
import { BREAKPOINTS, EsArtworkDto, useColumnView, useViewport } from "@kunsten/core";
import { useScrollTransition } from "@kunsten/core";

export default defineComponent({
    name: "SearchResultsFourColumn",
    components: { SearchSimpleResult },
    props: {
        results: {
            type: Array as PropType<EsArtworkDto[]>,
            required: true,
        },
    },
    setup(props) {
        const columns = ref(4);

        const { matches: isMobile } = useViewport(BREAKPOINTS.mobile);

        const { getGroupedResults } = useColumnView<EsArtworkDto>();

        const groupedResults = computed(() => {
            return getGroupedResults(columns.value, props.results);
        });

        useScrollTransition();

        return { columns, groupedResults, isMobile };
    },
});
